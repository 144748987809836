import React from "react";
import Logo from "./../Assets/images/logo.svg";
import Scan from "./../Assets/images/scan.svg";
import { useStreamData } from "../Context/StreamDataContext";

const Intro = () => {
  const { step, setStep, words } = useStreamData();
  const steps: Array<number> = [1, 2, 3, 4];

  return (
    <div className="flex justify-between items-start flex-col z-9 h-full w-full pt-3 pb-3">
      <img src={Logo} alt="logo" className="w-[50%] ml-auto mr-auto" />
      <img src={Scan} alt="logo" className="h-[40%]" />
      <div className="w-full flex flex-col items-center justify-center space-y-2">
        <span className="text-gray-700 text-lg w-[75%] text-center">
          {words.intro.step_title}
        </span>
        {steps.map((step) => (
          <div className="flex justify-start items-center w-[75%] space-x-2" key={step}>
            <span className="text-primary font-bold text-2xl mr-2 text-sbuy-primary">{step}</span>
            <span className="text-gray-700 text-base">
              {words.intro[`step_${step}`]}
            </span>
          </div>
        ))}
      </div>
      <button
        className="bg-sbuy-primary text-white rounded-2xl w-80 h-12 text-xl ml-auto mr-auto"
        onClick={() => setStep(step + 1)}
      >
        {words.intro.start}
      </button>
    </div>
  );
};

export default Intro;
