/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Intro from "./Components/Intro";
import Permissions from "./Components/Permissions";
import BrandView from "./Components/BrandView";
import Config from "./Components/Config";
import Streaming from "./Components/Streaming";
import Preview from "./Components/Preview";
import { StreamDataProvider, useStreamData } from "./Context/StreamDataContext";

declare global {
  interface Window {
    io: any;
  }
}

function App() {
  const { step } = useStreamData();

  return (
    <div className="h-screen overflow-hidden flex items-center justify-center">
      <div className="overflow-hidden overflow-hidden h-full w-full sm:w-[400px] h-full sm:h-[90%] xl:h-[90%] max-h-[900px] sm:rounded-lg sm:border-2 sm:border-sbuy-primary relative">
        {step === 0 && <BrandView />}
        {step === 1 && <Intro />}
        {step === 2 && <Permissions />}
        {step === 3 && <Config />}
        {step === 4 && <Preview />}
        {step === 5 && <Streaming />}
      </div>
    </div>
  );
}

export default () => {
  return (
    <StreamDataProvider>
      <App />
    </StreamDataProvider>
  );
};
