import React, { useEffect, useRef } from "react";
import { useStreamData } from "../Context/StreamDataContext";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import Logo from "./../Assets/images/logo.svg";

const Preview = () => {
  const { step, setStep, words, options, setOptions } = useStreamData();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia(options.mediaConstraints)
      .then((stream) => {
        setOptions({ ...options, stream: stream });
        videoRef.current.srcObject = stream;
      });
  }, []);

  const stopCamera = () => {
    if (options.stream) {
      const videoTrack = options.stream.getVideoTracks()[0];
      videoTrack.stop();
    }
  };

  const goStreaming = () => {
    setStep(step + 1);
  };

  const goConfig = () => {
    stopCamera();
    setStep(3);
  };

  return (
    <div className="h-full flex flex-col pt-0 items-center bg-[#F7F7F7] justify-start">
      {/* Header */}
      <div className="w-full rounded-b-2xl bg-white flex justify-center items-center border-b border-[#CECECE] border-1 shadow border-opacity-60 h-[70px] relative">
        <ArrowLeftIcon
          className="absolute text-[#707070] left-5 w-[28px] cursor-pointer"
          onClick={goConfig}
        />
        <img alt="logo" src={Logo} className="w-[40%]" />
      </div>

      {/* Video */}
      <div className="rounded bg-[#F7F7F7] flex justify-center items-center w-[98%] h-[90%] overflow-hidden ">
        <video
          ref={videoRef}
          id="preview-video"
          playsInline
          muted
          autoPlay
          className="h-[100%]"
        />
      </div>

      {/* Button */}
      <button
        className="bg-sbuy-primary text-white rounded-2xl w-60 h-12 text-xl ml-auto mr-auto absolute bottom-5"
        onClick={goStreaming}
      >
        {words.preview.start}
      </button>
    </div>
  );
};

export default Preview;
