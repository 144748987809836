import { Constraints, SocketOptions, StreamingData } from "../Components/Models";

export const ENDPOINT = "https://streaming.vivet.app/";

/* 
    This method is used for socket options generation.
    Paramas: @Fps(frames per second), @AudioBR(audio bitrate).
*/
export const socketOptions: SocketOptions = {
    secure: true,
    reconnection: true,
    reconnectionDelay: 1000,
    timeout: 15000,
    pingTimeout: 15000,
    pingInterval: 45000,
};

/* 
    This method is used for catch the events emitted by server.
    Paramas: @SocketIO, @LoadCamera(show front video), @Data(rmtp info for dacast url generation).
*/
export const eventsGeneration = (socket, videoRef, mediaConstraints, strData: StreamingData, onError) => {
    socket.on('error', (err) => {
        console.log(err);
    });

    socket.on('connect_timeout', (e) => {
        console.log("Connection timeout: " + e);

    });

    socket.on('error', (e) => {
        console.log("Connection error= " + e);
    });

    socket.on('connect_error', (e) => {
        console.log("ConnectionError:" + e);
    });

    socket.on('message', (m) => {
        console.log('New message' + m);
    });

    socket.on('fatal', (m) => {
        console.log("Fatal socket error", m);
        onError();
    });

    socket.on('ffmpeg_stderr', (m) => {
        //console.log("FFMPEG", m)
    });

    socket.on('connect', () => {
        startToEmit(socket, mediaConstraints, strData);
    })

    addUnfocusPageListener(socket, onError);
}

/* 
    This method is used for the emission init.
    Paramas: @SocketIO, @LoadCamera(show front video), @Data(rmtp info for dacast url generation).
*/
const startToEmit = (socket, mediaConstraints: Constraints, data: StreamingData) => {
    (window.navigator as any).getUserMedia = getUserMediaFromCurrentNavigator();
    const rtmpDestination = `rtmp://${data.user}:${data.password}@${data.server}/${data.streamKey}`;

    navigator.mediaDevices.getUserMedia(mediaConstraints)
        .then((stream) => {
            socket.emit('config_rtmpDestination', rtmpDestination);
            socket.emit('start', 'start');

            mediaRecorderCreation(socket, stream);
        });
};

/* 
    This method is used for get the correct mediadevices by navigator.
    For example, mozilla, chrome, webkitNavigator, etc. 
*/
const getUserMediaFromCurrentNavigator = () => {
    return (
        navigator.mediaDevices.getUserMedia ||
        (window.navigator as any).mediaDevices.mozGetUserMedia ||
        (window.navigator as any).mediaDevices.msGetUserMedia ||
        (window.navigator as any).mediaDevices.webkitGetUserMedia
    );
};

/* 
    This method is used for decode and emit the camera in binary data to the server.
    The data is emmited every 250ms. 
    Paramas: @SocketIO, @CameraStream
*/
const mediaRecorderCreation = (socket, stream) => {
    const mediaRecorder = new MediaRecorder(stream);
    mediaRecorder.start(100); // PROBAR DISTINTOS VALORES.

    mediaRecorder.ondataavailable = function (e) {
        socket.emit("binarystream", e.data);
    }
};

const addUnfocusPageListener = (socket, setError) => {
    const userAgent = window.navigator.userAgent;
    const isAndroid = /Android/.test(userAgent);

    if (isAndroid) {
        document.addEventListener("visibilitychange", function () {
            if (document.visibilityState !== "visible") {
                socket.disconnect();
                setError();
            }
        });
    } else {
        window.addEventListener("pagehide", function () {
            setError();
        });
    }
}

