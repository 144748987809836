import MuteMic from "../Assets/images/mic-mute.svg";
import UnMuteMic from "../Assets/images/mic-unmute.svg";

export const audioOptions = ["Media", "Alta"];

export const framesOptions = ["20 fps", "30 fps", "60 fps"];

export const bitrateOptions = ["2000 kps"];

export const bitrateExtendedOptions = ["700 kps", "1000 kps", "2000 kps", "3000 kps", "4000 kps"];

export const muteMicrophone = (videoRef, muteRef, setMuted) => {
    videoRef.srcObject.getAudioTracks()[0].enabled = false;
    muteRef.src = MuteMic;
    setMuted(true);
};

export const unMuteMicrophone = (videoRef, muteRef, setMuted) => {
    videoRef.srcObject.getAudioTracks()[0].enabled = true;
    muteRef.src = UnMuteMic;
    setMuted(false);
};

export const pauseVideo = (videoRef) => {
    videoRef.getTracks()[0].stop();
}

export const playVideo = (videoRef) => {
    videoRef.getTracks()[0].play();
}


/**
 * this function verify previous permission and return a promise about this.
 * @param deviceType (camera or microphone)
 * @returns promise 
 */
function verifyPreviosPermission(deviceType) {
    return new Promise(function (resolve, reject) {
        if (!navigator.permissions || !navigator.permissions.query) {
            resolve(false);
        } else {
            navigator.permissions.query({ name: deviceType })
                .then(function (permissionStatus) {
                    resolve(permissionStatus.state === 'granted');
                })
                .catch(function (error) {
                    console.error(`${deviceType} permission Error:`, error);
                    resolve(false);
                });
        }
    });
}


/**
 * This function checks if the permission of the camera and the permission of the microphone are granted, 
 * in that case, it executes the permission success function, otherwise, it asks for both permissions
 * @param {*} streamingData 
 * @param {*} onPermissionGranted 
 * @param {*} getPermissions 
 */
export function checkOrGetCurrentPermissions(onPermissionGranted, onPermissionDeny) {
    verifyPreviosPermission('camera').then(function (hasCameraPermission) {
        if (hasCameraPermission) {
            verifyPreviosPermission('microphone').then(function (hasMicrophonePermission) {
                if (hasMicrophonePermission) {
                    onPermissionGranted();
                } else {
                    getPermissions(onPermissionGranted, onPermissionDeny);
                }
            });

        } else {
            getPermissions(onPermissionGranted, onPermissionDeny);
        }
    });
};

/**
 * This function get permissiones from camera an mic by media devices api.
 * @param {*} eventData 
 * @param {*} onPermissionGranted 
 */
const getPermissions = (onPermissionGranted, onPermissionDeny) => {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(res => {
        if (res) onPermissionGranted();
    }).catch(() => onPermissionDeny());
}

/**
 * This function prevent screen lock in chrome/safari android.
 */
export function preventScreenLock() {
    if ('wakeLock' in navigator && 'request' in (navigator.wakeLock as any)) {
        (navigator.wakeLock as any).request('screen').then(function() {
        console.log('Prevent screen lock activated');
      }).catch(function() {
        console.error('Screen block prevention is not available in this device');
      });
    }
  }

  export function activeScreenLock() {
    if ('wakeLock' in navigator && 'release' in (navigator.wakeLock as any)) {
      (navigator.wakeLock as any).release().then(function() {
        console.log('Screen lock activated');
      }).catch(function() {
        console.error('Screen lock activation error');
      });
    }
  }