import React, { useEffect } from "react";
import Logo from "./../Assets/images/logo.svg";
import { checkOrGetCurrentPermissions } from "../Sources/DevicesHelper";
import { StreamingData } from "./Models";
import { useStreamData } from "../Context/StreamDataContext";

const BrandView = () => {
  const apiUrls: { prod: string; staging: string } = {
    prod: "https://streambuy.cubiq.digital/api",
    staging: "https://api-staging.streambuy.net/api",
  };
  const {
    streamingData,
    step,
    setStreamingData,
    setPermissionsGranted,
    setEventData,
    setStep,
  } = useStreamData();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const eventId: string = query.get("eventId");
    setInitialAppData(query, eventId);
  }, []);

  function setInitialAppData(query: URLSearchParams, eventId): void {
    const streamData: StreamingData = {
      user: query.get("user"),
      password: query.get("password"),
      server: query.get("server"),
      streamKey: query.get("streamkey"),
    };
    setStreamingData(streamData);
    fetchEventData(eventId);
  }

  /**
   * Get event data from api.
   * @param {*} eventId
   */
  const fetchEventData = (eventId) => {
    fetch(`${apiUrls.prod}/event/${eventId}`)
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          setEventData(result.response);
          checkOrGetCurrentPermissions(onPermissionGranted, onPermissionDeny);
        }
      });
  };

  const onPermissionGranted = () => {
    setPermissionsGranted(true);
    setStep(step + 1);
  };

  const onPermissionDeny = () => {
    setStep(step + 1);
  };

  return (
    <div className="flex items-center justify-center h-full bg-background-brand bg-cover">
      <img alt="logo" src={Logo} />
    </div>
  );
};

export default BrandView;
