import React, { useEffect, useState } from "react";
import Logo from "./../Assets/images/logo.svg";
import Calendar from "../Assets/images/calendar.svg";
import Time from "../Assets/images/time.svg";
import {
  audioOptions,
  bitrateExtendedOptions,
  framesOptions,
} from "../Sources/DevicesHelper";
import moment from "moment";
import "moment/locale/es";
import {
  StreamingOptions,
  configSelected,
  configUnselected,
  mediaConstraints,
} from "./Models";
import { useStreamData } from "../Context/StreamDataContext";
import {
  ArrowLeftIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";

const Config = () => {
  const [showBitrates, setShowBitrates] = useState(false);
  const [selectedFrame, setSelectedFrame] = useState("20 fps");
  const [selectedBitrate, setSelectedBitrate] = useState("2000 kps");
  const [selectedAudio, setSelectedAudio] = useState("Media");
  const [videoDevices, setVideoDevices] = useState(null);
  const [audioInputDevices, setudioInputDevices] = useState(null);
  const [cameraDevice, setCameraDevice] = useState(null);
  const [micDevice, setMicDevice] = useState(null);

  const [time, setTime] = useState("");
  const [date, setDate] = useState("");

  const { step, setStep, setOptions, words, eventData } = useStreamData();

  useEffect(() => {
    setDate(
      formatDate(
        eventData?.start_day.slice(0, eventData?.start_day.indexOf(" "))
      )
    );
    setTime(
      eventData?.start_day
        .slice(eventData?.start_day.indexOf(" "))
        .substring(0, 6) + " hs"
    );
    navigator.mediaDevices.enumerateDevices().then((hardware) => {
      // Video devices
      const videoDevices = hardware.filter((h) => h.kind === "videoinput");
      setVideoDevices(videoDevices);
      setCameraDevice(videoDevices[0].deviceId);
      // Audio devices
      const audioDevices = hardware.filter((h) => h.kind === "audioinput");
      setudioInputDevices(audioDevices);
      setMicDevice(audioDevices[0].deviceId);
    });
  }, []);

  const formatDate = (date) => {
    const momentDate = moment(date, "YYYY-MM-DD");
    return `${momentDate.format("dddd DD")} de ${momentDate.format(
      "MMMM"
    )} de ${momentDate.format("YYYY")}`;
  };

  const goPreview = () => {
    const options: StreamingOptions = {
      fps: selectedFrame,
      audio: selectedAudio === "Media" ? "11025" : "22050",
      bitrate: selectedBitrate,
      mediaConstraints: {
        ...mediaConstraints,
        video: { ...mediaConstraints.video, deviceId: cameraDevice },
        audio: { deviceId: { exact: micDevice } },
      },
    };
    setOptions(options);
    setStep(step + 1);
  };

  const selectorOptions = videoDevices?.map((device) => (
    <option key={device.deviceId} value={device.deviceId}>
      {device.label.includes("front") || device.label.includes("back")
        ? device.label.includes("front")
          ? "Cámara frontal"
          : "Cámara principal"
        : device.label}
    </option>
  ));

  const micSelectorOptions = audioInputDevices?.map((device) => (
    <option key={device.deviceId} value={device.deviceId}>
      {device.label}
    </option>
  ));

  const GeneralConfigs = (
    <div className="h-full flex flex-col pt-0 pb-3 justify-start items-center bg-[#F7F7F7] space-y-5">
      {/* Header */}
      <div className="w-full rounded-b-2xl bg-white flex justify-center items-center border-b border-[#CECECE] border-1 shadow border-opacity-60 h-[70px] relative">
        <ArrowLeftIcon
          className="absolute text-[#707070] left-5 w-[28px] cursor-pointer"
          onClick={() => setStep(1)}
        />
        <img alt="logo" src={Logo} className="w-[40%]" />
      </div>

      {/* Config card */}
      <div className="w-[90%] bg-white rounded-2xl flex flex-col justify-start items-center space-y-3 pb-3">
        {/* Title */}
        <div className="w-full rounded-t-2xl bg-white flex justify-center items-center border-b border-[#CECECE] border-1 shadow border-opacity-60 h-[50px]">
          <span className="text-sbuy-secondary font-bold">
            {eventData?.name}
          </span>
        </div>

        {/* Date and time */}
        <div className="w-full flex flex-col justify-center items-center space-y-1">
          <div className="w-[75%] flex justify-start items-center space-x-2 capitalize">
            <img src={Calendar} alt="calendar" width="20" />
            <div>{date}</div>
          </div>
          <div className="w-[75%] flex justify-start items-center space-x-2">
            <img src={Time} alt="calendar" width="20" />
            <div>{time}</div>
          </div>
        </div>
        <hr className="h-[1px] w-full" />

        {/* Video frames */}
        <div className="w-[75%] flex-col justify-start items-center space-y-1">
          <div>{words.config.videoFrames}</div>
          <div className="flex justify-start items-center space-x-5">
            {framesOptions.map((frame) => (
              <div className="option-shadow">
                <div
                  className={
                    selectedFrame === frame ? configSelected : configUnselected
                  }
                  onClick={() => setSelectedFrame(frame)}
                  key={frame}
                >
                  {frame}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Video Bitrate */}
        <div className="w-[75%] flex-col justify-start items-center space-y-1">
          <div>{words.config.videoBitrate}</div>
          <div className="flex justify-start items-center space-x-3">
            <div className={configSelected} key={selectedBitrate}>
              {selectedBitrate}
            </div>
            <div
              className="flex justify-center items-center text-[30px] text-[#707070] cursor-pointer"
              onClick={() => setShowBitrates(true)}
            >
              +
            </div>
          </div>
        </div>

        {/* Video Audio */}
        <div className="w-[75%] flex-col justify-start items-center space-y-1">
          <div>{words.config.audio}</div>
          <div className="flex justify-start items-center space-x-5">
            {audioOptions.map((audio) => (
              <div
                className={
                  selectedAudio === audio ? configSelected : configUnselected
                }
                onClick={() => setSelectedAudio(audio)}
                key={audio}
              >
                {audio}
              </div>
            ))}
          </div>
        </div>

        <div className="w-[75%] flex-col justify-start items-center space-y-1">
          <div>{words.config.selectCamera}</div>
          <select
            className="border border-1 border-[#707070] w-[100%] h-[30px] rounded-xl"
            name="cameras"
            id="cameras"
            onChange={(e) => setCameraDevice(e.target.value)}
          >
            {selectorOptions}
          </select>
        </div>

        <div className="w-[75%] flex-col justify-start items-center space-y-1">
          <div>{words.config.selectMic}</div>
          <select
            className="border border-1 border-[#707070] w-[100%] h-[30px] rounded-xl"
            name="mics"
            id="mics"
            onChange={(e) => setMicDevice(e.target.value)}
          >
            {micSelectorOptions}
          </select>
        </div>

        <button
          className="bg-sbuy-primary text-white rounded-2xl w-60 h-12 text-xl ml-auto mr-auto"
          onClick={goPreview}
        >
          {words.config.preview}
        </button>
      </div>
    </div>
  );

  const BitrateSelection = (
    <div className="h-full flex flex-col pt-0 pb-3 justify-start items-center bg-[#F7F7F7] space-y-5">
      {/* Header */}
      <div className="w-full rounded-b-2xl bg-white flex justify-center items-center border-b border-[#CECECE] border-1 shadow border-opacity-60 h-[70px]">
        <img alt="logo" src={Logo} className="w-[40%]" />
      </div>

      <div className="w-[90%] bg-white rounded-2xl flex flex-col justify-start items-center space-y-8 pb-3">
        {/* Title */}
        <div className="w-full rounded-t-2xl bg-white flex justify-center items-center border-b border-[#CECECE] border-1 shadow border-opacity-60 h-[50px] relative">
          <ArrowLeftIcon
            className="absolute text-[#707070] left-5 w-[20px] cursor-pointer"
            onClick={() => setShowBitrates(false)}
          />
          <span className="text-sbuy-secondary font-bold">
            {words.config.videoBitrate}
          </span>
        </div>
        <div className="w-[75%] flex flex-col justify-center items-center space-y-4">
          {bitrateExtendedOptions.map((bitrate) => (
            <div
              onClick={() => setSelectedBitrate(bitrate)}
              key={bitrate}
              className={
                selectedBitrate === bitrate ? configSelected : configUnselected
              }
            >
              {bitrate}
            </div>
          ))}
        </div>

        <button
          className="bg-sbuy-primary text-white rounded-2xl w-60 h-12 text-xl ml-auto mr-auto"
          onClick={() => setShowBitrates(false)}
        >
          {words.config.bitrateSelect}
        </button>
      </div>
    </div>
  );

  const VideoDevicesError = (
    <div className="w-[80%] bg-white flex flex-col justify-center items-center space-y-5 p-4 text-center rounded-lg shadow">
      <span className="text-sbuy-secondary font-bold text-[20px]">
        {words.config.not_devices}
      </span>
      <ExclamationTriangleIcon className="w-[45px] cursor-pointer text-sbuy-secondary" />
      <span className="text-[14px]">{words.config.not_devices_explain}</span>
    </div>
  );

  return !videoDevices ? (
    VideoDevicesError
  ) : (
    <> {!showBitrates ? GeneralConfigs : BitrateSelection} </>
  );
};

export default Config;
