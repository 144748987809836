import React, { useState } from "react";
import {
  StreamContext,
  StreamingData,
  StreamingOptions,
} from "../Components/Models";
import words from "../Assets/es.json";

const StreamDataContext = React.createContext<StreamContext>({
  streamingData: null,
  eventData: null,
  permissionsGranted: false,
  step: 0,
  options: null,
  words: words,
  socket: null,
  setStreamingData: () => {},
  setPermissionsGranted: () => {},
  setEventData: () => {},
  setStep: () => {},
  setOptions: () => {},
  setSocket: () => {},
});

export function StreamDataProvider(props) {
  const [step, setStep] = useState(0);
  const [socket, setSocket] = useState<any>(null);
  const [streamingData, setStreamingData] = useState<StreamingData>();
  const [eventData, setEventData] = useState<any>(null);
  const [permissionsGranted, setPermissionsGranted] = useState<boolean>(false);
  const [options, setOptions] = useState<StreamingOptions>(null);
  
  const value = React.useMemo(() => {
    return {
      streamingData,
      eventData,
      permissionsGranted,
      words,
      options,
      step,
      socket,
      setStreamingData,
      setPermissionsGranted,
      setEventData,
      setStep,
      setOptions,
      setSocket,
    };
  }, [streamingData, eventData, permissionsGranted, step, options, socket]);

  return (
    <StreamDataContext.Provider
      value={value}
      {...props}
    ></StreamDataContext.Provider>
  );
}

export function useStreamData() {
  const context = React.useContext(StreamDataContext);
  if (!context) {
    throw new Error("Context error");
  }
  return context;
}
