import React, { useEffect, useRef, useState } from "react";
import {
  ENDPOINT,
  eventsGeneration,
  socketOptions,
} from "../Sources/SocketConfig";
import {
  activeScreenLock,
  muteMicrophone,
  preventScreenLock,
  unMuteMicrophone,
} from "../Sources/DevicesHelper";
import { useStreamData } from "../Context/StreamDataContext";
import {
  ExclamationTriangleIcon,
  SpeakerXMarkIcon,
} from "@heroicons/react/24/solid";
import { SpeakerWaveIcon } from "@heroicons/react/24/solid";

const Streaming = () => {
  const [muted, setMuted] = useState(false);
  const [socketError, setSocketError] = useState(false);
  const { streamingData, options, setSocket, words } = useStreamData();

  // DOM refs.
  const videoRef = useRef<HTMLVideoElement>(null);
  const muteRef = useRef(null);

  useEffect(() => {
    videoRef.current.srcObject = options.stream;
    connectToSocket();
    //preventScreenLock();
    //activeScreenLock();
  }, []);


  /* 
    This method is used for connect to SocketIO with static ENDPOINT to ref server socket.
    */
  const connectToSocket = () => {
    const socketOptsQuery = {
      framespersecond: options.fps || 30,
      audioBitrate: options.audio || 22050,
    };
    const socketOpts = { ...socketOptions, query: socketOptsQuery };

    const newSocket = window.io.connect(ENDPOINT, socketOpts);

    setSocket(newSocket);

    eventsGeneration(
      newSocket,
      videoRef,
      options.mediaConstraints,
      streamingData,
      onError
    );

    setSocketError(false);
  };

  const onMuteOrUnmute = () => {
    if (muted) unMuteMicrophone(videoRef.current, muteRef.current, setMuted);
    else muteMicrophone(videoRef.current, muteRef.current, setMuted);
  };

  const onError = () => {
    setSocketError(true);
  };

  return (
    <div className="w-full h-full  items-center bg-[#F7F7F7] justify-start">
      {socketError ? (
        <div className="w-full h-full bg-[#F7F7F7] flex justify-center items-center">
          <div className="w-[80%] bg-white flex flex-col justify-center items-center space-y-5 p-4 text-center rounded-lg shadow">
            <span className="text-sbuy-secondary font-bold text-[20px]">
              {words.streaming.error}
            </span>
            <ExclamationTriangleIcon className="w-[45px] cursor-pointer text-sbuy-secondary" />
            <span className="text-[14px]">{words.streaming.error_explain}</span>
            <button
              className="rounded-full h-[40px] shadow font-bold pl-4 pr-4"
              onClick={connectToSocket}
            >
              {words.streaming.reconnect}
            </button>
          </div>
        </div>
      ) : (
        <div className="w-full h-full">
          {/* Live */}
          <div className="absolute flex justify-center items-center bg-white text-[#FF0000] right-2 top-2 space-x-2 pt-1 pb-1 pr-2 pl-2 font-bold rounded-lg">
            <span> • </span>
            <span>{words.streaming.live}</span>
          </div>
          {/* Video */}
          <div className="rounded bg-[#F7F7F7] flex justify-center items-center h-[99%] w-[98%] overflow-hidden mt-[1%] ml-auto mr-auto">
            <video
              className="h-[100%]"
              ref={videoRef}
              id="preview-video"
              playsInline
              muted
              autoPlay
            />
          </div>
          {/* Actions */}
          <div className="w-full absolute bottom-2 flex justify-center items-center space-x-20">
            <div className="flex justify-center items-center bg-white rounded-lg p-1">
              {muted ? (
                <SpeakerXMarkIcon
                  className="left-5 w-[28px] cursor-pointer text-[#231F20]"
                  onClick={onMuteOrUnmute}
                />
              ) : (
                <SpeakerWaveIcon
                  className="left-5 w-[28px] cursor-pointer text-[#231F20]"
                  onClick={onMuteOrUnmute}
                />
              )}
            </div>
            <div className="flex justify-center items-center bg-sbuy-primary rounded-2xl p-2 cursor-pointer">
              <span
                className="text-white"
                id="disconnect"
                onClick={() => window.location.reload()}
              >
                {words.streaming.end}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Streaming;
