export interface StreamContext {
  streamingData: StreamingData;
  eventData: any;
  permissionsGranted: boolean;
  step: number;
  options: StreamingOptions;
  words: any;
  socket: any;
  setStreamingData: (data: StreamingData) => void;
  setPermissionsGranted: (status: boolean) => void;
  setEventData: (data: any) => void;
  setStep: (step: number) => void;
  setOptions: (options: StreamingOptions) => void;
  setSocket: (socket: any) => void;
}

export interface StreamingData {
  user?: string | null;
  password?: string | null;
  server?: string | null;
  streamKey?: string | null;
}

export interface StreamingOptions {
  fps: string;
  audio: string;
  bitrate: string;
  mediaConstraints: Constraints;
  stream?: MediaStream;
}

export interface Constraints {
  audio: {
    deviceId: {
      exact: string;
    };
  } | boolean;
  video: {
    deviceId: string;
    width?: {
      min: number;
      ideal: number;
      max: number;
    };
    height?: {
      min: number;
      ideal: number;
      max: number;
    };
  };
}

export interface SocketOptions {
  secure: boolean;
  reconnection: boolean;
  reconnectionDelay: number;
  timeout: number;
  pingTimeout: number;
  pingInterval: number;
}

export const configSelected =
  "bg-sbuy-secondary rounded-xl text-white w-[75px] h-[35px] flex justify-center items-center cursor-pointer";
export const configUnselected =
  "bg-[#707070] rounded-xl text-white w-[75px] h-[35px] flex justify-center items-center cursor-pointer";

export const mediaConstraints: Constraints = {
  audio: null,
  video: {
    deviceId: null,
    width: {
      min: 1280,
      ideal: 1280,
      max: 1280,
    },
    height: {
      min: 720,
      ideal: 720,
      max: 720,
    },
  },
};
