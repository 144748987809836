import React, { useEffect, useState } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { useStreamData } from "../Context/StreamDataContext";

const Permissions = () => {
  const [error, setError] = useState<string | null>(null);
  const { setStep, words, permissionsGranted, setPermissionsGranted } =
    useStreamData();

  const constraints = {
    audio: true,
    video: true,
  };

  useEffect(() => {
    if (permissionsGranted) setStep(3);
  }, []);

  const onPermissionGranted = () => {
    setPermissionsGranted(true);
    setStep(3);
  };

  const onPermissionDenied = () => {
    const permissionSkipped = "prompt";
    const permissionBlocked = "denied";
    const permissionName = "camera" as PermissionName;
    navigator.permissions.query({ name: permissionName }).then((res) => {
      if (res.state === permissionSkipped) setError("Skipped");
      if (res.state === permissionBlocked) setError("Blocked");
    });
  };

  const onClickPermission = () => {
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(() => {
        onPermissionGranted();
      })
      .catch(() => {
        onPermissionDenied();
      });
  };

  const onErrorAccept = () => {
    if (error === "Skipped") setError(null);
    else if (error === "Blocked")
      window.open(
        "https://support.google.com/chrome/answer/114662?hl=es-419&co=GENIE.Platform%3DDesktop",
        "_blank"
      );
  };

  const PermissionReq = (
    <div className="w-[80%] bg-white flex flex-col justify-center items-center space-y-5 p-4 text-center rounded-lg shadow">
      <span className="text-sbuy-primary font-bold text-[20px]">
        {words.permissions.permissions}
      </span>
      <span className="text-[14px]">
        {words.permissions.permissionsContent}
      </span>
      <button
        className="rounded-full w-[40px] h-[40px] shadow font-bold"
        onClick={onClickPermission}
      >
        {words.permissions.ok}
      </button>
    </div>
  );

  const PermissionError = (
    <div className="w-[80%] bg-white flex flex-col justify-center items-center space-y-5 p-4 text-center rounded-lg shadow">
      <span className="text-sbuy-secondary font-bold text-[20px]">
        {error === "Skipped"
          ? words.permissions.permissionsDenied
          : words.permissions.permissionsBlocked}
      </span>
      <ExclamationTriangleIcon className="w-[45px] cursor-pointer text-sbuy-secondary" />
      <span className="text-[14px]">
        {error === "Skipped"
          ? words.permissions.permissionsContentDenied
          : words.permissions.permissionsContentBlocked}
      </span>
      <button
        className="rounded-full w-[40px] h-[40px] shadow font-bold"
        onClick={onErrorAccept}
      >
        {words.permissions.ok}
      </button>
    </div>
  );

  return (
    <div className="h-full w-full flex justify-center items-center bg-[#F7F7F7]">
      {!error ? PermissionReq : PermissionError}
    </div>
  );
};

export default Permissions;
